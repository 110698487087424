<script setup>
import Tempus from '@darkroom.engineering/tempus'

const props = defineProps({
    color: {
        type: String,
        default: 'white',
    },
    isSticky: {
        type: Boolean,
        default: true,
    },
    prevSticky: {
        type: Boolean,
        default: true,
    },
    blocks: {
        type: Array,
        default: () => [],
    },
    panelId: {
        type: String,
        default: '',
    },
    paddingTop: {
        type: [Number, String],
        default: -1,
    },
    paddingBottom: {
        type: [Number, String],
        default: -1,
    },
    shouldDrawGradientCircles: {
        type: Boolean,
        default: true,
    },
})

const root = ref(null)
const { observe } = useResizeObserver()
const { top, height, bottom } = observe(root)
const cleanBlocks = computed(() => props?.blocks.filter(b => b?.typeHandle))
const colorName = getTailwindColorName(props.color)

// pt-0 pt-9 pt-18 pt-32 pt-48
// pb-0 pb-9 pb-18 pb-27 pb-40 pb-57 -> + 9 when it's sticky
let paddingTopClasses = 'pt-18'
if (props.paddingTop !== -1 && props.paddingTop !== null) {
    paddingTopClasses = `pt-${props.paddingTop}`
}
let paddingBottomClasses = 'pb-32'
if (props.paddingBottom !== -1 && !props.isStick && props.paddingBottom !== null) {
    paddingBottomClasses = `pb-${props.paddingBottom}`
}
if (props.paddingBottom !== -1 && props.isSticky && props.paddingBottom !== null) {
    paddingBottomClasses = `pb-${(parseInt(props.paddingBottom, 10) + 9)}`
}

const gradientCirclesColors = ref(null)
const { innerHeight, scrollTop } = useClientState()
const heightCalc = ref(0)

const allowedColors = ['midblue', 'lightblue']
if (colorName === 'midblue') {
    gradientCirclesColors.value = ['0, 71, 187']
}
else if (colorName === 'lightblue') {
    gradientCirclesColors.value = ['100, 152, 237']
}
const textColorName = bgToText(colorName)

const offsetTop = ref(null)
const calculateOffsetTop = () => {
    let concatStyle = null
    if (props.isSticky) {
        const maxedHeight = Math.max(heightCalc.value, innerHeight.value)
        const diff = (innerHeight.value - maxedHeight).toFixed(4)
        concatStyle = `calc(${diff}px - var(--geloso-offset-top))`
    }
    return concatStyle
}
const offsetRaw = ref(0)
const initialOffset = ref(0)

const calculateOffsetRaw = () => {
    let offsetRawValue = 0
    if (props.isSticky) {
        const maxedHeight = Math.max(heightCalc.value, innerHeight.value)
        const diff = (innerHeight.value - maxedHeight).toFixed(4)
        offsetRawValue = Math.abs(diff)
    }
    return offsetRawValue
}
const calculated = ref(0)
const makeSticky = ref(false)
const prevTop = ref(0)
const shouldBeSticky = ref(false)

if (!isServer() && props.isSticky) {
    const calcVals = () => {
        if (root?.value) {
            heightCalc.value = height.value
            calculated.value = (root.value.getBoundingClientRect().bottom + scrollTop.value) + 300
            offsetTop.value = calculateOffsetTop()
            offsetRaw.value = calculateOffsetRaw()
        }
    }
    /**
     * Maybe should not be recalculated every frame
     * but necessary for the recalc of height when accordions
     * and other stuff are changing
     */
    Tempus.add(calcVals, 4)

    onUnmounted(() => {
        Tempus.remove(calcVals)
    })
}

const invisibleTop = computed(() => {
    const val = scrollTop.value - initialOffset.value
    return val
})

onMounted(() => {
    onNuxtReady(() => {
        // Only add the sticky class on mounted otherwhise there's a jump due
        // to offsetTop not being computed right away
        if (props.isSticky) {
            initialOffset.value = root?.value?.getBoundingClientRect()?.bottom + scrollTop.value
            nextTick(() => {
                makeSticky.value = true
            })
        }
    })
})
</script>

<template>
    <div
        :id="panelId"
        class="panel-anchor"
    />

    <section
        ref="root"
        class="panel rounded-t-4xl last:rounded-b-4xl"
        :class="['bg-'+colorName, 'text-'+textColorName, paddingTopClasses, paddingBottomClasses, {
            'min-h-svh': isSticky,
            'overflow-clip first-of-type:-mt-31 first-of-type:rounded-t-4xl first-of-type:pt-41': allowedColors.includes(colorName),
            'first-of-type:mt-0 first-of-type:rounded-t-none': !allowedColors.includes(colorName),
            'relative z-10': !isSticky,
            '!invisible': isSticky && invisibleTop >= 0,
            'sticky': makeSticky,
            'notsticky': !makeSticky,
            '!rounded-t-none': !prevSticky,
            '-mt-12': prevSticky,
        }]"

        :style="{
            top: offsetTop,
        }"
    >
        <ClientOnly>
            <gradient-circles
                :min-radius="400"
                :max-radius="1000"
                :nombre-ronds="3"
                :speed=".002"
                :colors="gradientCirclesColors"
                :allow-overflow-x="true"
                :allow-overflow-y="false"
                :should-draw="allowedColors.includes(colorName) && shouldDrawGradientCircles"
            />
        </ClientOnly>
        <component
            :is="'Flexible'+block.typeHandle"
            v-for="(block, index) in cleanBlocks"
            :key="'block'+index"
            :data="block"
            :class="{
                'relative z-10': allowedColors.includes(colorName),
            }"
            :data-offset-top="offsetRaw"
            :color-name="colorName"
            :panel-id="panelId"
        />
    </section>
</template>
